
.breadcrumb-list {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .breadcrumb-item {
    margin-right: 10px;
  }
  
  .breadcrumb-item a {
    text-decoration: none;
    color: #007bff;
  }
  
  .breadcrumb-item.active {
    color: #6c757d;
    pointer-events: none;
  }
  
  .breadcrumb-item::after {
    content: '/';
    margin-left: 10px;
  }
  
  .breadcrumb-item:last-child::after {
    content: '';
  }
  