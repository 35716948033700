.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");
body,
button,
input,
select,
textarea {
    font: 14px/1.5 "Roboto", Arial, "Microsoft YaHei", sans-serif;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

/* src/App.css */

.subcategory-ul {
    right: -85%;
    top: 0;
    width: 200px;
    padding: 10px;
    display: none;
    z-index: 9999;
    border: 1px solid #d5d5d5;
    @apply bg-white absolute;
}

.category-list {
    padding: 0;
}

.category-list > li {
    position: relative;
    padding: 5px 10px;
    margin: 0;
    cursor: pointer;
}

.category-list > li:hover > .subcategory-ul {
    display: block;
}

.subcategory-ul > li:hover > .subcategory-ul {
    display: block;
}

.category-list > li a {
    color: #000000;
}

.category-list > li a:hover {
    color: #cf2532;
}

/* .main-menu {
  background: #CF2532 !important;
} */

/* .category-list {
  display: flex;
} */

.right-two-banner img {
    border-radius: 15px;
}

.main-slider .react-multi-carousel-list {
    display: flex;
    align-items: center;
    overflow: hidden;
    position: relative;
    border: 1px solid #d5d5d5;
    border-radius: 15px;
    height: 582px;
}

.main-slider .react-multi-carousel-list img {
    height: 582px;
    object-fit: cover;
}

.top-banner-container .sidebar-cat-container {
    box-shadow: rgba(109, 103, 89, 0.06) 0px 2px 30px 9px;
    border: 1px solid #e6e7eb;
}

.right-two-banner-bottom {
    margin-top: 24px;
}

.view-all-btn {
    background: #cf2532 !important;
    width: 100%;
    display: block;
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    color: #ffffff;
    border-radius: 0 0 10px 10px;
}

.view-all-btn:hover {
    background: #c57c43 !important;
}

.sidebar-cat-container h1 {
    margin-bottom: 0px;
    padding: 10px;
    font-weight: 600;
    background: #ffffff !important;
    width: 100%;
    display: block;
    height: 40px;
    line-height: 24px;
    font-size: 18px;
    color: #cf2532 !important;
    border-radius: 0;
    border-bottom: 1px solid #d5d5d5;
    text-align: center;
    margin-bottom: 9px;
}

.menu-list {
    margin-bottom: 6px;
}

.menu-list a:hover {
    color: #cf2532;
}

.popular-category-row {
    background: #fffaf5;
    padding: 50px 0;
    margin-top: 50px;
}

.container {
    margin: 0 auto;
}

.cat-box {
    display: inline-block;
    overflow: hidden;
    border: 0;
    -webkit-border-radius: 14px;
    -moz-border-radius: 14px;
    border-radius: 14px;
    height: auto;
    width: 33.3%;
    display: -webkit-inline-box;
    display: -webkit-inline-flex;
    display: -moz-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    padding: 0 20px;
}

.cat-box-inner {
    padding: 10px;
    border: 1px solid #c5c5c5;
    background: #ffffff;
}

.popular-row {
    display: flex;
}

.popular-row a {
    text-align: center;
    width: 200px;
}

.cat-box img {
    min-width: 50px;
}

.enquiry-row {
    margin-top: 50px;
}

.formbox-container {
    border: 1px solid #d5d5d5;
    height: auto;
    width: 100%;
    border-radius: 20px;
}

.form-right {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.form-right h2 {
    font-size: 30px;
    font-weight: bold;
    color: #cf2532;
}

.form-right h3 {
    font-size: 20px;
    font-weight: bold;
}

.form-right input {
    height: 56px;
    margin: 0 auto;
    width: 100%;
    border: 1px solid #d5d5d5;
    padding: 10px;
    border-radius: 8px;
}

.enquiry-form {
    margin-top: 20px;
    max-width: 500px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
    text-align: center;
}

.post-btn {
    float: none;
    margin-top: 0;
    margin-left: 0;
    background: #cf2532 !important;
    border: 1px solid #cf2532 !important;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 8px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1;
    padding: 0.84375em 0.875em 0.78125em !important;
    -webkit-box-shadow: 0 1px 0 #ccc;
    box-shadow: 0 1px 0 #ccc;
    text-transform: capitalize;
    width: 100%;
    height: 50px;
    font-size: 20px !important;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: bold;
    line-height: 14px;
}

.new-arrival-row {
    box-shadow: rgba(109, 103, 89, 0.06) 0px 2px 30px 9px;
    border-radius: 8px;
    padding: 20px;
}

.sidebar {
    width: 16%;
    margin-right: 24px;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem;
    box-shadow: rgba(67, 89, 113, 0.12) 0px 2px 6px;
    border-radius: 8px;
}

.right-sidebar {
    width: 16%;
    margin-left: 24px;
    background-color: rgb(255, 255, 255);
    padding: 0.5rem;
    box-shadow: rgba(67, 89, 113, 0.12) 0px 2px 6px;
    border-radius: 8px;
}

.left-contant {
    width: 67%;
    text-align: left;
}

.product-container {
    margin-bottom: 100px !important;
    max-width: 100%;
}

.product-title {
    font-weight: 400;
    font-size: 18px;
    color: #cf2532 !important;
    cursor: pointer;
    text-transform: capitalize;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    line-height: 22px;
}

.btn.btn-latest-price {
    color: #2e368f !important;
    padding: 2px 10px;
    margin-left: 0px;
    font-size: 14px !important;
    font-weight: bold !important;
    display: inline-block;
    text-decoration: none !important;
    border: 1px solid #2e368f;
    border-radius: 25px;
    line-height: 24px !important;
    transition: all ease 0.3s;
    margin-top: 10px;
}

.tabledesc table {
    border-collapse: collapse;
    width: 100%;
    margin-bottom: 8px;
    max-width: 100%;
    margin: 10px 0;
}

.tabledesc table td {
    font-size: 14px;
    line-height: 14px;
    padding: 8px 5px;
    border-bottom: 1px solid #eaeaeac7;
}

.product-grid-box {
    padding: 10px;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    display: flex;
    margin-bottom: 20px;
}

.product-img {
    cursor: pointer;
}

.seller-row {
    display: flex;
    flex-wrap: wrap;
}

.attribute-box {
    width: 100%;
}

.seller-box {
    border: 2px solid #cf2532;
    width: 50%;
    padding: 10px;
    border-radius: 10px;
    font-size: 13px;
}

.seller-name {
    font-weight: bold;
    color: #555555;
    font-size: 18px;
    text-transform: capitalize;
}

.seller-name img {
    width: 35px;
    height: 35px;
    margin-right: 10px;
}

.trust-seller {
    display: flex;
    margin: 10px 0;
}

.trusted-text {
    flex-direction: column;
    align-items: flex-start;
    font-size: 10px;
    line-height: 10px;
    color: rgb(84, 167, 47);
    display: flex !important;
    justify-content: center;
    margin-left: 5px;
}

.gst-number {
    color: #858585;
}

.contact-seller-row {
    display: flex;
    width: 100%;
    margin-bottom: 10px;
}

.send-inq-btn {
    padding: 0;
    border: 1px solid #cf2532;
    margin-right: 10px;
    border-radius: 50px;
    margin-top: 0px;
    font-size: 14px;
    background: #cf2532;
    font-weight: 400;
    color: #ffffff;
    width: calc(50% - 10px);
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.send-inq-btn svg {
    margin-right: 10px;
    font-size: 20px;
}

.send-inq-btn:hover {
    border: 1px solid #cf2532;
    background: #ffffff;
    color: #cf2532;
}

.view-number-btn:hover {
    border: 1px solid #cf2532;
    background: #cf2532;
    color: #ffffff;
}

.view-number-btn {
    padding: 10px 10px;
    border: 1px solid #cf2532;
    margin-right: 10px;
    border-radius: 50px;
    margin-top: 0px;
    font-size: 14px;
    background: #ffffff;
    font-weight: 400;
    color: #cf2532;
    width: calc(50% - 10px);
    align-items: center;
    justify-content: space-around;
    height: 33px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.view-number-btn a {
    display: flex;
    justify-content: center;
    align-items: center;
}

.view-number-btn svg {
    font-size: 20px;
}

.right-sidebar img {
    width: 100%;
}

.react-responsive-modal-modal {
    width: 500px;
    border-radius: 10px;
}

.inq-title {
    font-size: 30px;
    font-weight: bold;
    border-bottom: 2px solid #000000;
    margin-bottom: 10px;
}

.inq-product-name {
    background: #ffffff;
    color: #cf2532;
    font-weight: bold;
    font-size: 16px;
    padding: 5px 0;
    border-radius: 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-style: italic;
}

.form-col {
    width: 100%;
}

.react-responsive-modal-modal .form-right {
    padding: 10px 0;
}

.login-content {
    width: 30%;
}

.login-form-box {
    width: 50%;
    border: 1px solid #d5d5d5;
    padding: 20px;
    margin: 0px;
    border-radius: 20px;
}

.login-advt {
    width: 50%;
    border: 0;
    border-radius: 20px;
    margin-left: 25px;
}

.category-advt-banner img {
    width: 100%;
    margin-bottom: 20px;
}

.login-advt img {
    border-radius: 10px;
}

.login-container {
    display: flex;
    width: 100%;
    max-width: 1130px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    align-items: start;
}

.login-advt .react-multiple-carousel__arrow {
    display: none !important;
}

.login-txt {
    color: #000000;
    font-size: 14px;
    font-weight: bold;
    text-align: left;
    margin-top: 10px;
    line-height: 27px;
    margin-bottom: 15px;
}

.login-title {
    font-size: 24px;
    color: #cf2532;
    text-align: center;
}

.login-content-col .email-row p {
    margin-bottom: 15px;
    background: url(https://madeintimorleste.com/seller/wp-content/uploads/2024/08/email-icon.png)
        no-repeat left center;
    background-size: 17px;
    padding-left: 24px;
    text-align: left;
}

.login-content-col .contact-row p {
    margin-bottom: 15px;
    text-align: left;
    background: url(https://madeintimorleste.com/seller/wp-content/uploads/2024/08/phone-icon.png)
        no-repeat left center;
    background-size: 17px;
    padding-left: 16px;
}

/* input[type="text"]{
  background-color: #fff!important;
  border: 1px solid #ccc!important;
  -moz-border-radius: 3px;
  -webkit-border-radius: 3px;
  border-radius: 3px;
  padding: 8px 10px;
  width: 100%;
  margin-bottom: 15px;
  font-size: 15px;
  display: inline-block;
  box-shadow: 1px 1px 5px 0px #E9E9E9;
  line-height: 18px;
  height: 50px;
} */

.product-title-name-inner {
    font-size: 22px;
    color: #cf2532;
    font-weight: bold;
    margin-bottom: 10px;
    line-height: 28px;
    border-bottom: 1px solid #c5c5c5;
    padding-bottom: 15px;
}

.product-detail-content {
    margin-top: 50px;
}

.pro-description {
    margin-top: 20px;
}

.main-menu .top-cat-title {
    display: none;
}

.main-menu {
    background: url(https://madeintimorleste.com/seller/wp-content/uploads/2024/09/menu-bg.jpg);
    border-top: 1px solid #e6e7eb;
    border-bottom: 1px solid #e6e7eb;
}

.main-menu .main-ul {
    display: flex;
    margin: 0 auto;
    width: 100%;
    max-width: 1600px;
    padding: 0;
}

.main-menu .main-ul > li {
    padding: 0 15px;
    height: 40px;
    line-height: 40px;
}

.main-menu .view-all-btn {
    display: none !important;
}

.main-menu .bg-white.sidebar-cat-container {
    background: none !important;
}

.main-menu .main-ul > li > h2 > a {
    color: #000000 !important;
    font-size: 14px;
    font-weight: normal;
    text-transform: none;
}

.main-menu .main-ul > li > h2 {
    margin-bottom: 0;
}

.main-menu .main-ul > li:nth-child(n + 9) {
    display: none !important;
}

.main-menu ul .subcategory-ul {
    right: auto;
    top: 100%;
    width: 200px;
    padding: 0;
    display: none;
    z-index: 9999;
    position: absolute;
    background-color: #b8221a;
    left: 0;
}

.main-menu ul .subcategory-ul ul.subcategory-ul {
    right: auto;
    top: 0;
    left: 100%;
}

.main-menu ul .subcategory-ul li {
    line-height: 40px;
    border-bottom: 1px solid #cf2532;
    margin-bottom: 0;
    padding: 0 10px;
}

.main-menu ul .subcategory-ul li:hover {
    background: #cf2532;
}

.main-menu ul .subcategory-ul li h2 a {
    color: #ffffff;
    font-size: 16px;
}

.main-menu ul .subcategory-ul li h2 {
    margin-bottom: 0;
}

.page-title {
    font-size: 30px;
    margin-bottom: 20px;
    margin-top: 20px;
}

.sub-page-title {
    text-align: center;
    font-size: 24px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #cf2532;
}

.enq-list-container {
    width: 100%;
    max-width: calc(100% - 300px);
    padding-left: 30px;
}

.enq-container {
    display: flex;
}

.enq-pro-img {
    width: 100px;
    height: 100px;
}

.enq-product-image-box {
    width: 150px;
}

.enq-product-content {
    width: calc(100% - 150px);
    padding-left: 20px;
}

.enq-list-li {
    border-bottom: 1px solid #d5d5d5;
    margin-bottom: 20px;
    padding: 20px;
}

.my-account-sidebar {
    width: 300px;
    float: left;
    height: 500px;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
}

.bread-container {
    margin: 20px auto;
    font-size: 14px;
}

.bread-container a {
    font-size: 14px;
    color: #cf2532 !important;
}

.breadcrumb-item::after {
    content: "/";
    margin-left: 10px;
    color: #cf2532 !important;
}

.breadcrumb-item.active {
    color: #000000 !important;
    pointer-events: none;
}

.no-result-found {
    background: #cf2532;
    color: #ffffff;
    text-align: center;
    font-size: 16px;
    padding: 10px;
    margin-bottom: 20px;
}

.category-name-title {
    color: #cf2532;
    font-size: 36px;
    margin-bottom: 20px;
}

.category-description-text {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 20px;
}

.product-img-box {
    width: 200px;
    height: 200px;
}

.product-img-box img {
    height: 200px;
    object-fit: cover;
}

.product-detail-left {
    width: 100%;
    padding-right: 0;
}

.main-footer {
    background: url(https://madeintimorleste.com/seller/wp-content/uploads/2024/09/menu-bg.jpg);
}

.login-title {
    font-size: 24px;
    color: #cf2532;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: 800;
}

.inner-footer-box {
    background: rgba(0, 0, 0, 0.6);
    padding: 20px;
    border-radius: 10px;
    margin-right: 20px;
    max-width: 300px;
    margin: 0px auto;
    min-height: 244px;
}

.inner-footer-box h3 {
    font-size: 24px;
    border-bottom: 1px solid #ffffff;
    padding-bottom: 5px;
    margin-bottom: 10px;
}

.input-box {
    height: 56px;
    border: 1px solid #d5d5d5;
    padding: 8px 10px;
    box-shadow: 1px 1px 5px 0px #e9e9e9;
    border-radius: 8px !important;
    width: 100%;
    background: #ffffff;
}

:focus-visible {
    outline: none;
}

.login-btn {
    float: none;
    margin-top: 0;
    margin-left: 0;
    background: #cf2532 !important;
    border: 1px solid #cf2532 !important;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 8px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1;
    padding: 0.84375em 0.875em 0.78125em !important;
    -webkit-box-shadow: 0 1px 0 #ccc;
    box-shadow: 0 1px 0 #ccc;
    text-transform: capitalize;
    width: 100%;
    height: 50px;
    font-size: 20px !important;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: bold;
    line-height: 14px;
    max-width: 350px;
    margin: 0 auto;
    display: block;
}

.login-btn:hover {
    background: #950c16 !important;
}

.register-link-box {
    text-align: center;
    font-size: 20px;
}

.register-link-box a {
    font-size: 20px;
    color: #cf2532;
    text-decoration: underline;
    margin-left: 10px;
    font-weight: bold;
}

.register-link-box a:hover {
    text-decoration: none;
}

.product-detail-containter {
    display: flex;
    width: 100%;
    margin-bottom: 50px;
}

.product-media {
    width: 50%;
    background: #fafafa;
    border-radius: 10px;
    padding: 30px;
}

.product-media img {
    height: 500px;
    width: 500px;
    object-fit: cover;
}

.media-inner-box {
    display: flex;
    justify-content: center;
}

.product-info {
    width: 50%;
    padding-left: 30px;
}

.related-product-container {
    margin-top: 50px;
    margin-bottom: 50px;
    border-top: 1px solid #cccccc;
    padding-top: 20px;
}

.pro-contant {
    display: flex;
    flex-wrap: wrap;
}

.product-card {
    padding: 10px;
    width: calc(100% / 6);
    padding: 10px;
}

.product-inner {
    border: 0;
    border-radius: 5px;
    padding: 0px;
}

.pro-img {
    width: 100%;
}

.related-title {
    font-size: 24px;
    line-height: 30px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 50px;
}

.pro-img img {
    width: 100%;
    height: 214px;
    object-fit: cover;
    border-radius: 10px;
}

.pro-name {
    margin-top: 10px;
    min-height: 45px;
    color: #222;
    white-space: normal;
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-weight: 400;
}

.pro-price {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #000000;
    margin-top: 0px;
}

.pro-seller {
    font-size: 14px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #cf2532;
    margin-top: 10px;
}

.pro-inq-btn {
    width: 100%;
}

.pro-inq-btn a {
    float: none;
    width: 100%;
    margin-top: 0;
    margin-left: 0;
    background: #cf2532 !important;
    border: 1px solid #cf2532 !important;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    border-radius: 8px;
    color: #fff;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 1;
    padding: 0 !important;
    -webkit-box-shadow: 0 1px 0 #ccc;
    box-shadow: 0 1px 0 #ccc;
    text-transform: capitalize;
    width: 100%;
    height: 40px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif !important;
    font-weight: bold;
    line-height: 40px;
    max-width: 350px;
    margin: 0 auto;
    display: block;
}

.pro-inq-btn a:hover {
    background: #950c16 !important;
}

.all-cat-menu-btn {
    background: #cf2532;
    position: relative;
    width: auto;
    padding: 0 20px;
    text-align: center;
}

.all-cat-menu-btn > span {
    color: #ffffff;
}

.all-menu-hover {
    display: none;
    position: absolute;
    z-index: 9999;
    background: #ffffff;
    height: auto;
    width: 550px;
    position: absolute;
    z-index: 999;
    left: 0;
    top: 100%;
    padding: 15px;
    border-radius: 0 5px 5px 5px;
    border: 1px solid #c2c2c2;
}

.all-cate-menu {
    display: flex;
}

.all-cat-menu-btn:hover .all-menu-hover {
    display: block;
}

body .main-menu .all-menu-hover ul li h2 a {
    color: #000000 !important;
}

body .main-menu .all-menu-hover ul li h2 a:hover {
    color: #cf2532 !important;
}

body .main-menu .all-cat-menu-btn .subcategory-ul {
    background: #e5e5e5;
    top: 0;
    right: -100%;
    left: auto;
    width: 100%;
    display: none;
}

.main-menu .all-menu-hover .main-ul li:hover > .subcategory-ul {
    display: block;
}

body .main-menu .all-cat-menu-btn .subcategory-ul li {
    line-height: 40px;
    border-bottom: 1px solid #c2c2c2;
    margin-bottom: 0;
    padding: 0 10px;
    width: 100% !important;
}

body .main-menu .all-cat-menu-btn .subcategory-ul li h2 a {
    font-size: 14px;
}

.main-menu .all-menu-hover .main-ul li:hover {
    background: #e5e5e5;
}

.main-menu .all-menu-hover .main-ul li:hover > h2 a {
    color: #000000;
}

.main-menu .all-menu-hover .main-ul {
    flex-wrap: wrap;
}

.main-menu .all-menu-hover .main-ul li {
    flex-wrap: wrap;
    width: 50%;
}

.main-menu .all-menu-hover .sidebar-cat-container {
    background: none !important;
    box-shadow: none !important;
}

.top-banner-container.container {
    margin-top: 30px;
}

.main-menu .all-menu-hover .main-ul > li:nth-child(n + 9) {
    display: block !important;
}

.image-gallery-thumbnails img,
.photo-gallery img {
    width: 75px;
    height: 75px;
    border: 2px solid #d5d5d5;
}

.image-gallery-thumbnails,
.photo-gallery {
    margin-top: 20px;
    padding: 10px;
}

.photo-gallery ul {
    display: flex;
    gap: 20px;
    justify-content: center;
}

.logout-btn {
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.order-history-link:hover,
.logout-btn:hover {
    color: #cf2532;
}

.logout-icon {
    font-size: 24px;
    color: #cf2532;
    font-weight: bold;
}

.order-history-link {
    font-size: 14px;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
}

.cart-icon {
    font-size: 24px;
    color: #cf2532;
    font-weight: bold;
}

.prod-info-left {
    width: 50%;
    float: left;
}

.prod-info-right {
    width: 50%;
    float: left;
}

.prod-info-right .seller-box {
    width: 100%;
    border: 0;
}

.prod-info-left .seller-row .attribute-box {
    width: 100%;
}

.related-product-container .product-card {
    cursor: pointer;
}

.related-product-container .product-card:nth-child(n + 7) {
    display: none;
}

/* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

/* Style the buttons inside the tab */
.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
}

/* Change background color of buttons on hover */
.tab button:hover {
    background-color: #ddd;
}

/* Create an active/current tablink class */
.tab button.active {
    background-color: #ccc;
}

/* Style the tab content */
.tabcontent {
    display: none;
    padding: 6px 12px;
    border: 1px solid #ccc;
    border-top: none;
}

.prod-attri-box {
    padding-top: 20px;
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-bottom: 20px;
}

.prod-attri-box h2 {
    font-size: 22px;
    font-weight: bold;
}

.pro-tab-container {
    display: flex;
    align-items: start;
}

.pro-tab-container .seller-box {
    width: 340px;
    border-radius: 4px;
    background: #fff;
    box-shadow: 0px 4px 8px 0px rgba(214, 219, 232, 0.8);
    border: 0;
}

.pro-tab-container .seller-box .seller-name {
    position: relative;
    display: flex;
    align-items: flex-start;
    padding: 15px;
    background: linear-gradient(
        90deg,
        #e6ecf2 -16%,
        rgba(236, 241, 245, 0.09) 105%
    );
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
    cursor: pointer;
    margin-bottom: 10px;
}

.tab-container {
    width: calc(100% - 360px);
    margin-right: 20px;
}

.product-info .send-inq-btn {
    height: 44px;
    font-size: 16px;
    font-weight: bold;
}

.product-info .view-number-btn {
    height: 44px;
    font-size: 16px;
    font-weight: bold;
}

.short-description-box {
    border-top: 1px solid #cccccc;
    padding-top: 15px;
    font-size: 16px;
    line-height: 26px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    margin-top: 15px;
}

body .react-tabs__tab {
    color: #222;
    display: block;
    height: 41px;
    float: left;
    padding: 8px 50px 7px;
    color: #555;
    border: 1px solid #fafcff;
    border-bottom-color: #e9e9e9;
    border-top-color: #e9e9e9;
    margin-top: -1px;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    padding-top: 8px;
    font-weight: bold;
    padding: 8px 50px 7px;
    font-size: 16px;
}

body .react-tabs__tab--selected {
    background: #fff;
    border-bottom-color: #fff;
    border-right-color: #e9e9e9;
    border-left-color: #e9e9e9;
    border-top: 3px solid #e64545;
    border-radius: 0;
}

body .react-tabs__tab-list {
    width: 100%;
    overflow: hidden;
    background: #fafcff;
    margin-bottom: 0;
}

body .react-tabs__tab-panel {
    width: 100%;
    padding: 20px;
}

.react-tabs {
    border: 1px solid #e9e9e9;
}

.tab-description-content {
    font-size: 16px;
    line-height: 24px;
}

.react-tabs__tab-panel .details-photo {
    padding: 10px;
}

.react-tabs__tab-panel .details-photo ul li {
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
}

.static-top-menu li {
    padding: 0 25px;
    height: 40px;
    line-height: 40px;
}

.static-top-menu li a {
    background: #cf2532;
    padding: 8px 10px;
    border-radius: 5px;
    color: white;
    font-weight: bold;
}

.static-top-menu li a:hover {
    color: #ffffff;
    font-weight: bold;
}

.static-top-menu {
    display: flex;
}

.search-col {
    position: relative;
}

.search-wrapper {
    top: 100%;
    background: #ffffff;
}

.header-container {
    padding: 5px 10px;
}

.logo-img {
    width: 180px;
}

.con-icon {
    color: red;
    font-size: 30px;
    margin: 0 auto;
}

.con-box {
    padding: 0;
    text-align: center;
    font-size: 14px;
    width: 100%;
    position: relative;
    margin-bottom: 30px;
    border-bottom: 1px solid #dde6e3;
    padding-bottom: 20px;
}

.con-box:last-child {
    border-bottom: 0;
    margin-bottom: 0;
    padding-bottom: 0;
}

.cont-form input[type="email"],
.cont-form input[type="text"] {
    height: 56px;
    margin-bottom: 30px;
}

.con-box h3 {
    font-size: 24px;
    font-weight: bold;
}

.con-box .con-icon {
    font-size: 50px;
    margin: 0 auto;
    left: 0;
    top: 0;
}

.cont-inner-container {
    max-width: 100%;
    width: 100%;
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 30px;
}

.icon-box {
    margin-bottom: 15px;
    position: relative;
    display: inline-flex;
    border-radius: 50%;
    transition: 0.4s;
    width: 40px;
    height: 40px;
    min-width: 40px;
    align-items: center;
    justify-content: center;
    background: #edf4f2;
    width: 80px;
    height: 80px;
    min-width: 80px;
    margin: 0px 0px 20px 0px;
}

.con-info-box {
    border-style: solid;
    border-width: 1px 1px 1px 1px;
    border-color: #dde6e3;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
        box-shadow 0.3s;
    padding: 40px 60px 40px 60px;
    border-radius: 20px 20px 20px 20px;
}

.go-map-box {
    margin-bottom: 30px;
    width: 100%;
}

.go-map-box iframe {
    width: 100% !important;
}

.add-info {
    font-size: 16px;
    max-width: 60%;
    margin: 0px auto;
}

.menu-toggle {
    display: none;
}

.search-icon-mobile {
    display: none;
}

.close-menu-btn,
.mobile-login-col {
    display: none;
}

.search-inner-box {
    display: flex;
    width: 100%;
}

.footer-inner-container a:hover {
    text-decoration: underline;
}

.about-container {
    margin-bottom: 50px;
    font-size: 16px;
    line-height: 24px;
}

.about-container .sub-page-title {
    text-align: left;
}

.container {
    padding: 0 15px;
}

.foot-logo-box img {
    width: 100%;
    max-width: 100px;
}

.foot-logo-box {
    display: flex;
    gap: 20px;
}

.react-multi-carousel-item {
    display: flex;
}

.react-multi-carousel-track img {
    height: 100%;
}

.eye-catch {
    font-weight: bold;
    font-style: italic;
    color: #000000;
    margin-top: 10px;
    display: block;
    font-size: 20px;
    text-align: right;
    margin-top: 20px;
}

.go-map-box {
    display: flex;
}

.map-box {
    width: 30%;
}

.cont-form-box {
    width: calc(60% - 60px);
    padding-left: 20px;

    background: #edf4f2;
    transition: background 0.3s, border 0.3s, border-radius 0.3s,
        box-shadow 0.3s;
    margin: 0px 60px 0px 0px;
    margin-left: 60px;
    margin-right: 0px;
    padding: 60px 55px 60px 55px;
    border-radius: 20px 20px 20px 20px;
}

.cont-form-box h3 {
    margin-bottom: 0;
    font-size: 48px;
    font-weight: bold;
}

.cont-form-box p {
    margin-bottom: 20px;
    font-size: 16px;
}

.contact-btn {
    background: #cf2532;
    max-width: 300px;
    border-radius: 50px;
    height: 60px;
    /* margin: 0 auto; */
    width: 300px;
    font-size: 22px;
}

.go-map-box {
    margin-top: 50px;
}

.cont-form {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    display: grid;
    gap: 20px;
}

@media (max-width: 767px) {
    .main-menu {
        display: none;
    }
    .close-menu-btn {
        display: block;
    }
    .search-icon-mobile {
        display: block;
        color: #cf2532 !important;
        font-size: 30px;
        font-weight: bold;
    }
    .menu-toggle {
        display: block;
        color: #cf2532 !important;
        font-size: 30px;
        font-weight: bold;
    }

    .login-col,
    .search-col {
        display: none;
    }

    .header-grid {
        display: flex;
    }

    .header-container .login-col {
        width: 80%;
    }

    .logo-img {
        width: 100px;
    }

    .header-container {
        background: #ffffff;
        border-bottom: 1px solid #d5d5d5;
        position: relative;
    }

    .header-container .logo-col {
        width: 100%;
        display: flex;
        justify-content: space-between;
    }

    .banner-grid {
        display: flex;
        flex-wrap: wrap;
        padding: 0 10px;
    }

    .left-cat-home {
        width: 100%;
        margin-bottom: 20px;
    }

    .right-banner-home {
        width: 100%;
    }

    .left-cat-home {
        display: none;
    }
    .search-mobile-btn {
        position: absolute;
        right: 50px;
    }
    .menu-btn {
        position: absolute;
        right: 10px;
    }
    body.menu-open .main-menu {
        display: block;
    }

    .main-menu {
        display: none; /* Hide menu by default */
        /* other styling */
    }

    .main-menu {
        display: none; /* Hide menu by default */
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: #ffffff !important; /* Background color for the menu */
        z-index: 1000;
        transition: transform 0.3s ease-in-out;
        transform: translateX(100);
        padding-top: 30px;
    }

    .main-menu .all-menu-hover .main-ul li {
        width: 100%;
    }

    .close-menu-btn {
        position: absolute;
        top: 10px;
        right: 10px;
        color: #000000;
        font-size: 30px;
    }

    .close-menu-btn svg {
        fill: #000000;
    }

    .main-menu .container.flex {
        flex-wrap: wrap;
    }

    .all-cate-menu {
        width: 100%;
    }

    .all-cat-menu-btn {
        padding: 0;
        background: none;
        width: 100%;
    }

    .static-top-menu {
        display: flex;
        flex-direction: column;
        border-top: 1px solid #d5d5d5;
        width: 100%;
        margin-top: 10px;
        padding-top: 10px;
    }

    .all-menu-hover {
        position: static;
        display: block;
        padding: 0;
        width: 100%;
        border: 0;
    }

    .all-cat-menu-btn > span {
        display: none !important;
    }
    .main-menu .all-menu-hover .main-ul {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .static-top-menu li {
        padding: 0px;
        height: 40px;
        line-height: 40px;
    }

    .mobile-login-col {
        border-top: 1px solid #d5d5d5;
        padding-top: 20px;
        width: 100%;
        display: flex;
    }

    .search-open .search-col {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: #ffffff; /* Background color for the menu */
        z-index: 1000;
        transition: transform 0.3s ease-in-out;
        padding: 20px;
    }
    .search-wrapper {
        top: 100%;
        background: #ffffff;
        position: static;
    }
    .search-inner-box {
        display: flex;
        width: 100%;
    }
    .search-icon-box {
        right: 0;
    }
    .slider-inner-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column-reverse;
    }
    .formbox-container {
        flex-direction: column;
    }
    .form-left {
        width: 100%;
    }
    .form-right {
        width: 100%;
        margin-top: 20px;
    }
    .new-arrival-container {
        display: flex;
        flex-direction: column;
    }
    .popular-row {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    .cat-box {
        width: 100%;
    }

    .product-container {
        flex-direction: column;
        padding: 0 10px;
    }

    .sidebar {
        width: 100%;
        display: none;
    }

    .product-container .left-contant {
        width: 100%;
        margin-bottom: 20px;
    }

    .right-sidebar {
        width: 100%;
        margin: 0;
    }

    .product-img-box img {
        height: 100px;
        object-fit: cover;
    }

    .product-img-box {
        width: 100px;
        height: 100px;
    }
    .prod-info-left {
        width: 100%;
        float: left;
    }

    .bread-container {
        margin: 10px 0;
        font-size: 14px;
        padding: 0 10px;
    }

    .prod-info-left .seller-row {
        display: none;
    }

    .product-detail-containter {
        display: flex;
        width: 100%;
        margin-bottom: 50px;
        flex-wrap: wrap;
    }

    .tab-container {
        width: 100%;
        margin-right: 0;
    }

    .related-title {
        margin-bottom: 10px;
    }

    .related-product-container {
        margin-top: 20px;
        margin-bottom: 50px;
        border-top: 1px solid #cccccc;
        padding-top: 20px;
        padding: 0 10px;
        padding-top: 20px;
    }

    .pro-tab-container .seller-box {
        width: 100%;
    }

    .related-product-container .product-card {
        cursor: pointer;
        width: calc(100% / 2);
    }

    .pro-tab-container {
        display: flex;
        align-items: start;
        flex-wrap: wrap;
    }

    .product-info {
        width: 100%;
        padding: 0 10px;
    }

    .product-media {
        width: 100%;
        background: #fafafa;
        border-radius: 10px;
        padding: 10px;
    }

    .category-name-title {
        color: #cf2532;
        font-size: 24px;
        margin-bottom: 0;
    }

    .product-detail-content {
        margin-top: 0;
    }

    .breadcrumb-list {
        flex-wrap: wrap;
    }

    .prod-info-right {
        width: 100%;
        float: left;
    }

    .footer-inner-container {
        margin: 0;
        padding: 0;
        display: flex;
        flex-direction: column;
    }

    .main-footer {
        padding: 0 10px;
        padding-top: 20px;
    }

    .about-container {
        padding: 0 10px;
    }

    .page-title {
        font-size: 24px;
        margin-bottom: 20px;
        margin-top: 20px;
    }

    .about-container .sub-page-title {
        font-size: 18px;
    }

    .about-container {
        font-size: 14px;
    }

    .login-container {
        flex-direction: column;
        padding: 0 10px;
    }

    .login-form-box {
        width: 100%;
        border: 1px solid #d5d5d5;
        padding: 20px;
        margin: 0px;
        border-radius: 20px;
        margin-bottom: 20px;
    }

    .login-advt {
        margin: 0;
        width: 100%;
    }

    .container {
        padding: 0 15px;
        max-width: 100%;
    }

    body .con-box {
        width: 100%;
    }

    .go-map-box {
        flex-wrap: wrap;
    }

    .cont-form-box {
        width: 100%;
        margin: 0;
        margin-top: 30px;
        padding: 20px;
       
    }

    .inner-footer-box {
        max-width: 100%;
        margin-bottom: 20px;
    }

    .map-box {
        width: 100%;
    }

    .cont-form {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        display: grid;
        gap: 20px;
    }
}

.author-name {
    font-weight: bold;
    text-align: right;
    display: inline-block;
}

.smal-pos {
    font-size: 14px;
}

.gap-7.forgot-form {
    gap: 16px;
    text-align: center;
}

.page-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
}

.page-item {
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    margin-bottom: 5px;
}

.page-item-selected {
    padding: 10px;
    cursor: pointer;
    border: 1px solid #ccc;
    background-color: #cf2532;
    margin-bottom: 5px;
    color: #ffffff;
}

.page-list {
    margin: 20px auto;
    display: flex;
    justify-content: center;
}

.page-item-selected,
.page-item {
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
}

.curtain {
    margin: 0 auto;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    position: fixed;
    z-index: 9999;
    background: red;
    left: 0;
    right: 0;
}

.curtain video {
    margin: 0 auto;
    width: 100vw !important;
    height: 100vh;
    overflow: hidden;
}
.curtain {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.9); /* Semi-transparent black */
    display: flex;
    align-items: center;
    justify-content: center;
    transition: opacity 1s ease-out, visibility 1s ease-out;
    opacity: 1;
    visibility: visible;
}

.fade-out {
    opacity: 0;
    visibility: hidden;
}
